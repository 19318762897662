import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { MockStore } from "redux-mock-store"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import NameUser from "../../../../shared/components/names/NameUser"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import { IAppState } from "../../../../app/appTypes"
import { getCaseById, getLeadCareUserByCaseId } from "../../../../shared/selectors/case"
import TextCaseType from "../../../../shared/components/text/TextCaseType"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import TargetDate from "./TargetDate"
import PatientNameWithLock from "./PatientNameWithLock"
import { generatePath } from "react-router-dom"
import { RoutePaths } from "../../../../app/routes/Routes"
import humps from "humps"

interface IProps {
  store?: MockStore
  caseId: string
  hasLink?: boolean
}

const defaultProps = {
  hasLink: true
}

const CaseDetails: React.FC<IProps> = (props: IProps): JSX.Element => {
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const leadCareUser = useSelector((state: IAppState) => getLeadCareUserByCaseId(state, props))
  if (!theCase || !theCase.id) return null

  const { t } = useTranslation(["shared", "case"])
  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )

  const renderDateOfBirth = (): JSX.Element => {
    return (
      <li>
        {t("case:dateOfBirth")}: <TimeDateOfBirth utcTime={theCase.dateOfBirth} />
      </li>
    )
  }

  const renderPatientHealthcareId = (): JSX.Element => {
    return (
      <li>
        {healthcareIdLabel}: {theCase.healthcareId || "-"}
      </li>
    )
  }

  const renderPatientCareProviderId = (): JSX.Element => {
    return (
      <li>
        {careProviderIdLabel}: {theCase.careProviderId || "-"}
      </li>
    )
  }

  const renderCaseType = (): JSX.Element => {
    return (
      <li>
        {t("case:type")}: <TextCaseType caseType={theCase.caseType} />
      </li>
    )
  }

  const renderLeadCare = (): JSX.Element => {
    if (!leadCareUser) return (
      <li>
        {t("case:leadCare")}: -
      </li>
    )
    return (
      <li>
        {t("case:leadCare")}: <NameUser
          firstName={leadCareUser.firstName}
          lastName={leadCareUser.lastName}
          professionalTitle={leadCareUser.professionalTitle}
          isPending={leadCareUser.pending}
        />
      </li>
    )
  }

  const renderClassification = (): JSX.Element => {
    return (
      <li>
        {t("shared:classification")}: {theCase.classification}
      </li>
    )
  }

  const renderSourceName = (): JSX.Element => {
    return (
      <li>
        {t("case:source")}: {theCase.sourceName}
      </li>
    )
  }

  const renderLastUpdated = (): JSX.Element => {
    return (
      <li>
        {t("case:lastUpdated")}: <TimeDefaultFormat utcTime={theCase.updatedAt} />
      </li>
    )
  }

  const renderSignedOffBy = (): JSX.Element => {
    if (!theCase.signedOffBy) return null
    return (
      <li>
        {t("case:signedOffBy")}: {theCase.signedOffBy}
      </li>
    )
  }

  const caseLinkPath = generatePath(RoutePaths.CASE, {
    caseId: theCase.id
  })

  const renderGender = (): JSX.Element => {
    const translationKey = humps.camelize(theCase.gender)
    return (
      <li>
        {t("case:gender")}: {t(`shared:${translationKey}`)}
      </li>
    )
  }

  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        <li>
          <PatientNameWithLock case={theCase} hasLink={props.hasLink} link={caseLinkPath} />
        </li>
        {renderGender()}
        {renderDateOfBirth()}
        {renderPatientHealthcareId()}
        {renderPatientCareProviderId()}
        {renderCaseType()}
      </ul>

      <ul className="col-span-1">
        {renderLeadCare()}
        {renderClassification()}
        {renderSourceName()}
        {renderLastUpdated()}
        <TargetDate caseId={props.caseId} />
        {renderSignedOffBy()}
      </ul>
    </div>
  )
}

CaseDetails.defaultProps = defaultProps

export default CaseDetails
